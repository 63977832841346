<template>
  <div class="input__container">
    <div
      v-if="props.label"
      class="text__headline-h5 text-dark-second mb-2"
    >
      <span>{{ props.label }}</span>
    </div>
    <v-text-field
      :id="name"
      ref="input"
      v-model.trim="model"
      :autocomplete="autocomplete"
      :class="{ 'input-readonly': props.readonly }"
      :color="mainColor"
      density="compact"
      :disabled="props.disabled"
      :error-messages="props.errorMessage"
      hide-details="auto"
      :name="props.name"
      :placeholder="props.placeholder"
      :readonly="props.readonly"
      single-line
      :type="fieldType"
      variant="outlined"
    >
      <template #prepend-inner>
        <slot name="prepend-inner" />
      </template>
      <template #append-inner>
        <slot name="append-inner-icon" />
        <Icon
          v-if="type === 'password'"
          class="cursor-pointer text-primary ml-2"
          :class="props.errorMessage?.length ? 'text-error' : 'text-primary'"
          :name="showPassword ? 'fa6-regular:eye-slash' : 'fa6-regular:eye'"
          size="18"
          @click.stop="showPassword = !showPassword"
        />
      </template>
    </v-text-field>
  </div>
</template>

<script lang="ts" setup>
type Props = {
  autocomplete?: string;
  disabled?: boolean;
  errorMessage?: string;
  label?: string;
  mainColor?: string;
  modelValue: string | unknown;
  name?: string;
  placeholder?: string;
  readonly?: boolean;
  type?: string;
};

const props = withDefaults(defineProps<Props>(), {
  autocomplete: 'off',
  disabled: false,
  errorMessage: '',
  label: '',
  mainColor: 'primary',
  name: '',
  placeholder: '',
  readonly: false,
  type: 'text'
});

const model = defineModel({ type: String });

const showPassword = ref(false);

const fieldType = computed(() => {
  return showPassword.value ? 'text' : props.type;
});

const inputBorderColor = computed(() => {
  if (props.errorMessage?.length) {
    return '2px solid rgb(var(--v-theme-error-500))';
  }
  return 'unset';
});
const backgroundColor = computed(() => {
  return props.errorMessage ? 'rgb(var(--v-theme-error-bg))' : 'rgb(var(--v-theme-input-bg))';
});
</script>

<style lang="scss" scoped>
.input {
  &__container {
    width: 100%;
  }

  &-readonly {
    & :deep(.v-field__input) {
      cursor: not-allowed;
    }

    :deep(.v-field--active) {
      box-shadow: none !important;
    }
  }

  &-readonly-pointer {
    & :deep(.v-field__input) {
      cursor: pointer;
    }
  }
}

:deep(.v-field) {
  background-color: v-bind('backgroundColor') !important;
  border-radius: 8px;
}

:deep(.v-input--density-compact) {
  --v-input-control-height: 52px;
}

:deep(.v-field__input) {
  padding-top: 10px !important;
}

:deep(.v-input__control) {
  border: v-bind(inputBorderColor);
  border-radius: 8px;
}

:deep(.v-messages__message) {
  color: rgb(var(--v-theme-error)) !important;
  font-size: 14px;
}

:deep(.v-field__outline) {
  --v-field-border-opacity: 0.18;
}
</style>
